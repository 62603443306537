<template>
    <div>
        <span class="text">智慧园区</span>
        <el-carousel trigger="click" :autoplay="true" height="650px" indicator-position="outside" class="Top">
        <el-carousel-item :key="1" >
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case42.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：腾讯科技(武汉)有限公司</div>
                    <div class="box-text-text2">工程内容：建筑智能化弱电系统、视频监控系统、能源管理系统、门禁系统、访客系统、报警系统、停车管理系统、信息发布系统、楼宇自控系统、IBMS系统、会议系统、大数据中心系统工程</div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="2">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case43.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：武汉软件产业基地</div>
                    <div class="box-text-text2">工程内容：建筑智能化弱电系统、机房系统工程(电气系统、UPS不间断电源系统、空调系统、新风系统防雷系统、接地系统)</div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="3">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case44.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：中冶南方工程技术有限公司</div>
                    <div class="box-text-text2">工程内容：建筑智能化弱电系统、机房系统工程(装饰装修系统、门禁系统、弱电系统、消防报警系统工程，消防灭火统、安防监控系统、防雷系统、UPS不间断电源系统)</div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="4">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case45.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：五环科技股份有限公司</div>
                    <div class="box-text-text2">工程内容：建筑智能化弱电系统、安防监控系统、网络系统集成、网络存储系统、视频会议系统、智慧停车管理系统、音视频可视对讲系统、机房系统工程</div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="5">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case46.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：中国化学工程第六建设有限公司</div>
                    <div class="box-text-text2">工程内容：建筑智能化弱电系统、数据网络(含云系统)、安全防范系统、智能一卡通系统、多媒体会议系统、信息发布系统、楼宇自控系统、机房工程系统、公共广播系统、有线电视系统</div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="6">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case47.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：中建三局宜昌建设发展有限公司</div>
                    <div class="box-text-text2">工程内容：视频监控系统、门禁系统、信息发布系统、可视对讲系统、无线对讲功能、停车管理收费系统、电子巡更、防盗报警系统、背景音乐系统、综合布线和宽带接入系统、计算机网络系统、电梯五方通话系统等等</div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="7">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case48.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1" style="text-indent:2rem;">武汉梦时代广场集零售、科技馆、海洋馆、冰雪乐园主题乐园于一体，打造华中乃至全国定位较高、规模最大的商业零售综合体。总投资超120亿元，计划2022年竣工交付是武汉市“十三五”重点工程项目。项目总用地面积约136亩，总建筑面积59.93万平方米，建筑高度85.4米，地下4月地上9层。规划地下机动停车位7287个、非机动车位1412个本项目工程智能化系统工程二包项目包括:公共广播与背音乐系统、紧急报警系统、视频监控系统、出入口及IC卡系统(门禁、考勤、消费 )、消控中心装修及配线间配电系统电梯通讯系统、管线桥架及系统(含室外)等部分</div>
                    
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="8">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case49.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1" style="text-indent:2rem;">中建三局新时代商务中心弱电建设项目，其主要建设范围包括:大楼综合布线、楼宇安全、公共广播、IP智能话、门禁监控、信息化网络安全建设、音视频会议系统建设，整个工程从确定项目立项后，前期展开需求调研，确客户建设需求，与客户多次召开需求讨论会议，确定各子系统建设要求及功能需求，汇总专项报告，并且针对中建局商秘安全网络安全建设提出的国家等级保护测评 2.0 标准为核心来规划各类配置方案，包括防火墙、行为审计、病毒软件等软硬件设备的选型，来进行多方面的讨论和评审，最终与客户以及中建总局安全部门一同确定最终的产品选型和功能应用。</div>
                    
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="9">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case50.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div  style="text-indent:2rem;">项目位于宁波(江北)高新技术产业园区，总规划面积约3平方公里。北邻余北快速路，南靠S61省道和机场高架，西面南北连接线，北接庆丰路。<br></div>
                    <div class="box-text-text1" style="text-indent:2rem;">小镇以膜材料、高端装备制造为主导产业，着力发展动力光电膜材料集聚区以及动力光电膜材料衍生产业集聚区。</div>
                </div>
            </div>
        </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">
.box{
    width: 100%;
    //height: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    flex-wrap: wrap;
    &-img{
        width: 95%;
        //height: 80%;
        display: flex;
        justify-content: center;
        position: relative;
        flex-wrap: wrap;
        top: 0rem;
        &-img1 {
            margin-top: 1rem;
            width: 100%;
            //height:100%;
        }
    }
    &-text{
        top: 4rem;
        line-height:1.5rem;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        width: 95%;
        font-size: 18px;
        font-weight: 600;
        position: relative;
        &-text1{
            text-align: start;
            width: 100%;
            height: 30px;
            margin-top: 0rem;
            //display: flex;
            position: relative;
            float: inline-start;
        }
        &-text2{
            //display: flex;
            flex-wrap: wrap;
            width: 100%;
            //height: 50px;
            position: relative;
        }
        &-text3{
            display: flex;
            position: relative;
            width: 7%;
        }
        &-text4{
            display: flex;
            position: relative;
            width: 93%;
        }
        &-text5{
            display: flex;
            position: relative;
            width: 100%;
            justify-content: center;
            font-weight: bold;
        }
        &-text6{
            //display: flex;
            position: relative;
            width: 49%;
            top: 1rem;
        }
    }
}
.text{
    margin-top: 2rem;
    line-height:2.5rem;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 30px;
    font-weight: bold;
}
.block{
    width: 100%;
}
 .el-carousel__item {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
    border: #475669;
 }
 .el-carousel__item:nth-child(n) {
     background-color: #ffffff;
  }
  .Top{
    margin-top: 1rem;
}
</style>